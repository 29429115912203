<template>
  <div class=" w-full xs:px-12 sm:px-12 lg:px-[15%]">
    <div class="flex gap-3 grid-cols-3 justify-center md:justify-between sm:gap-8 w-full  text-[17px] sm:text-[22px] pb-3 w-full">
        <div class="flex ">
          <div class="hidden sm:block">      <img src="../assets/peepo-trans.gif" class=" " width="40px"   alt="ArtPiece">          </div>
            <div class="hidden sm:block mt-2 text-[25px] outfit-400 font-semibold font-mono text-white/90 ml-4 mr-8">burnsol</div>
              <div class="flex gap-2  sm:gap-5 lg:gap-5 text-[18px] outfit-400 text-white/90">
                <a disabled href=""  class=" mt-[14px] ml-[15px] lg:ml-8 font-mono text-[18px] text-orange-400	">Home</a>
                <router-link to="/burn"  class=" mt-[14px] ml-4 lg:ml-[13px] font-mono text-[18px]">Burn</router-link>

                <a href="https://www.w3schools.com" target="_blank" class=" mt-[14px] ml-[13px] font-mono text-[18px]">Whitepaper</a>

              </div>
              <div class="flex gap-2 pt-[17px]  pl-2 lg:pl-8">
                <!-- Close (X) Icon Link -->
                <a href="https://twitter.com"  target="_blank" class=" ml-2 xs:ml-12 md:ml-12 lg:ml-0 ">
                  <img src="../assets/x.png"  width="20" height="20" alt="twitter icon">
                </a>
                 
                <a href="https://t.me"  target="_blank" class="ml-2 ">
                  <img src="../assets/telegram.webp" width="20" height="20" alt="Telegram icon">
                </a>
                <a href="https://discord.com"  target="_blank" class="ml-2 ">
                  <img src="../assets/dis.png" width="33" height="27" alt="dis icon">
                </a>
              </div>
            </div>  

        <div class="hidden lg:flex gap-2 pt-2  pl-2">
          <router-link to="/burn" ><button class="px-7 py-2  bg-gradient-to-r from-orange-400 to-red-500 hover:to-red-400 font-mono  rounded-full  text-white font-semibold text-[16px]">
            Burn
          </button></router-link>
        </div>

    </div>
    <div class="sm:flex w-full justify-between pt-24">
      <div class=" mx-auto sm:mx-0">
        <h1 class="text-xl xs:text-3xl sm:text-3xl  md:text-4xl text-left font-[400] w-[300px] md:w-[400px] ml-12 sm:ml-0">Burn your useless Solana NFT's and get rewarded with $BURN for helping cleanup the blockchain</h1>
        <h2 class="text-gray-300/60 md:w-[400px] w-[240px] text-left pt-4 font-light text-sm xs:text-xl sm:text-md md:text-xl ml-12 sm:ml-0">We take your rugged NFT's and reward you all while cleaning up the solana ecosystem</h2>
        <div class="flex justify-start  mt-8 "><router-link to="/burn"><button class="px-7 py-4 ml-12 sm:ml-0 bg-gradient-to-r from-orange-400 to-red-500 transition ease-in-out delay-150 hover:to-red-400 font-mono font-semibold  rounded-md text-white text-[16px]">
          Start Burning
        </button></router-link></div>
        <div class="flex pt-4 sm:pl-0 pl-12 ">
          <img src="../assets/pepe2.png" width="30" height="30" alt="burn">
          <p class="pt-[8px] font-mono font-semibold text-gray-300 mx-4 ">Total NFT's Burned : 233</p> 

        </div>
      </div>
      <div class=" ml-12 mt-12 sm:mt-0 ">                  
        <img src="../assets/tenor.gif" width="500" height="500" alt="burn">
      </div>
    </div>
    <div class="bg-white/40 h-[1px] w-full max-w-[1500px] mt-24 sm:ml-0 ml-12"></div>
    <div class="text-center mt-16 font-mono sm:pl-0 pl-12">
      <h1 class=" text-xl underline">How it works &#128187;</h1>
      <h2 class="mt-4 max-w-[1500px] text-left mx-auto"> &#128221;Our Smart Contract (Program) &#128221; will zero-out all account's data associated with the NFT you burn and reward you with the &#128293;$BURN&#128293; token for assisting in cleaning memory on the Solana blockchain! We currently accept Programmable NFT's, Non-Fungible Tokens. Inscriptions will be available soon! &#128293;Burn Baby Burn!&#128293; </h2>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'HomePage',
  };
  </script>