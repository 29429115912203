<template>
  <div class="">
       
      <div v-if="initialized">
        <div v-if="loading">
          <ul class="grid grid-cols-4 sm:grid-cols-4 lg:grid-cols-6 2xl:grid-cols-9 gap-x-8 gap-y-12 pb-12">
            <li v-for="i in 18" :key="i"  class=" bg-slate-200/20 animate-pulse mx-auto">
              <div class="w-36 h-36">
                <div class="h-36 overflow-hidden">
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div v-else>

          <div v-if="nfts.length === 0" class="pt-4">No NFTs found for your account, try reloading the page if this is not expected</div>
          <div v-if="nfts.length !== 0" class="pb-4">Select NFT's to Burn  ({{ nfts.length }})</div>

          <ul class="grid gap-x-0 grid-cols-1 xs:grid-cols-3   sm:grid-cols-5 lg:grid-cols-6 2xl:grid-cols-9 sm:gap-x-8 gap-y-12 pb-12">
            <li
              v-for="(nft) in pagenfts"
              :key="nft.fetched.pubkeyAdded"
              class="relative bg-gradient-to-r  from-slate-700/50 to-slate-500/30 w-24 sm:w-full rounded-bl-md rounded-br-md mx-auto border-2 border-white/20"
            >
              <div class="group h-28 w-full overflow-hidden relative">
                <img
                  v-if="nft.fetched.image"
                  :src="nft.fetched.image"
                  height="130px"
                  class="overflow-hidden transition duration-300 ease-in-out group-hover:opacity-30"
                  alt="NFT image"
                />
                <button
                  class="absolute inset-0 flex items-center justify-center opacity-0 transition duration-100 ease-in-out group-hover:opacity-100"
                  @click="burnMINT(nft)"
                >
                  <span class="px-4 py-2 bg-gradient-to-r from-orange-400 to-red-500 text-white rounded">Burn</span>
                </button>
              </div>
              <div>
                <p v-if="nft" class="outfit-400 text-xs font-semibold py-3 ">
                  {{ nft.metadata.data.data.name }}
                </p>
              </div>
            </li>
          </ul>

            <div v-if="!loading" class="flex  gap-4 justify-center pt-4 xl:pt-8 pb-12">
              <button class="" @click="prevPage" :hidden="currentPage === 1">Prev</button>
              <div v-for="(n,index) in numPages" :key="index" >
                <div v-if="index+1 == currentPage" >
                  <button disabled class="p-1  w-9 rounded-sm bg-white/40 hover:bg-white/50">
                    {{ index + 1 }} 
                  </button>  
                </div>
                <div v-else>
                  <button @click="choosePage(index)" class="p-1 bg-white/10 w-9 rounded-sm hover:bg-white/40">
                    {{ index + 1 }} 
                  </button>  
                </div>           
              </div>
              <button @click="nextPage" :hidden="currentPage * pageSize >= nfts.length">Next</button>

            </div>
        </div>
      </div>
      <div v-else-if="!initialized && loadinginitialized">
        <div role="status">
          <svg aria-hidden="true" class="inline w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
      </div>
      <div class="pt-6">Loading User Token Account...</div>
      </div>
      <div v-else>

        <button
        class=" w-36 h-12 bg-gradient-to-r from-orange-400 to-red-500 opacity-85 rounded-md hover:opacity-100"
        @click="initializeUser()"
      >
        Authentificate
      </button>

      </div>

      <ErrorModal ref="errorModal" />

  </div>
  </template>
  
  <script>
import { getNftsForOwner, fetchNftMetadata,burnNFT,initializeUser,checkifinitialized } from '@/services/solana';
import ErrorModal from './ErrorModal.vue';

  export default {
    components:{ErrorModal},

    data() {
      return {
        nfts: [],
        initialized: false,
        loadinginitialized:true,
        pagenfts:[],
        loading: true,
        ownerAddress: 'B', // Replace with the actual wallet address
        currentPage: 1,
        pageSize:27,
        numPages:[],
      };
    },
    async created() {
        try {
          let initialized = await checkifinitialized()
          console.log(initialized)
          console.log(this.loadinginitialized)
          this.initialized = initialized
          this.loadinginitialized=false
            const nftAccounts = await getNftsForOwner(this.ownerAddress);
            nftAccounts.sort((a, b) => {
                const mintA = a.account.data.parsed.info.mint.toLowerCase();
                const mintB = b.account.data.parsed.info.mint.toLowerCase();
                if (mintA < mintB) return -1;
                if (mintA > mintB) return 1;
                return 0;
            });
            //console.log(nftAccounts,"ALLNFTS ACCOUNTS");
            this.nfts = nftAccounts;
            const numpages2 = Math.ceil(nftAccounts.length/(this.pageSize))
            //console.log(numpages2)
            let arr = Array.from({ length: numpages2 }, () => numpages2);
            this.numPages = arr
            //console.log(this.numPages)
            this.fetchPaginatedNfts();

        } catch (error) {
            console.error('Error fetching NFTs:', error);
        }
    },

    /*watch: {
        paginatedNfts: {
            immediate: true,
            async handler(newPageNfts) {
            
                for (const [index, nft] of newPageNfts.entries()) {
                    if (!nft.metadata) {
                        const metadata = await fetchNftMetadata(nft.account.data.parsed.info.mint);
                        this.$set(this.nfts, (this.currentPage - 1) * this.pageSize + index, { ...nft, metadata });
                    }
                    if(this.nfts.length == 5){
                        this.loading = false;
                    }
                }
            }
        }
    },*/
    methods: {
      async triggerError(error) {
        if(error){
          this.$refs.errorModal.showError(error);

        }
        else{
          this.$refs.errorModal.showError('An unexpected error occurred.');
        }
    },
      async burnMINT(mint){
        try {
          const response = await burnNFT(mint);
          console.log(response,"RESPONSE")
        }catch (error) {
            console.error('Error fetching NFTs:', error);
            this.triggerError(error)
        }
      },
      async checkifinitialized(){
        try{
          const response = await checkifinitialized()
          console.log(response)
          return response

        }catch(e){
          console.log(e)
          this.triggerError(e)
        }
      },
      async initializeUser(){
        try {
          const response = await initializeUser();
          this.initialized = response
          console.log(response,"RESPONSE")
        }catch (error) {
            console.error('Error Initializing Account:', error);
            this.triggerError('Error Initializing Account:'+error)
        }
      },
        async fetchPaginatedNfts() {
            try {
                this.loading = true;
                const start = (this.currentPage - 1) * this.pageSize;
                const end = this.currentPage * this.pageSize;
                let targetNFTs = this.nfts.slice(start, end);
                this.pagenfts = [];

                const fetchMetadataPromises = targetNFTs.map(async (nft) => {
                if (!nft.metadata) {
                    const metadata = await fetchNftMetadata(nft.account.data.parsed.info.mint);
                       // console.log("inside page nfts metadata:",metadata);
                        this.pagenfts.push(metadata);

                }
                });

                await Promise.all(fetchMetadataPromises);
                //console.log(this.pagenfts,"PAGE NFTS")
            } catch (error) {
                console.error('Error fetching paginated NFTs:', error);
                this.triggerError('Error fetching paginated NFTs:'+error)

            } finally {
                this.loading = false;
            }
        },
     nextPage() {
      if (this.currentPage * this.pageSize < this.nfts.length) {
        this.currentPage++;
        this.fetchPaginatedNfts();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchPaginatedNfts();
      }
    },
    choosePage(page) {
        this.currentPage = page+1;
        this.fetchPaginatedNfts();
    },
    },
    
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  