import { programs } from '@metaplex/js';
import * as anchor from "@coral-xyz/anchor";
import { useAnchorWallet } from "solana-wallets-vue";
import { computed } from "vue";
import { AnchorProvider, web3 , Provider} from "@project-serum/anchor";
import { Program } from "@coral-xyz/anchor";
import {  Connection,clusterApiUrl, PublicKey} from "@solana/web3.js";
import { useWallet } from 'solana-wallets-vue';
export { TokenStandard } from '@metaplex-foundation/mpl-token-metadata';
import * as pda from './pda'

const SOLANA_NETWORK = 'https://alien-dimensional-county.solana-mainnet.quiknode.pro/8dd797f9530c73d6a499a7b28a17e1d28bfc4f0e/'; 
export const connection = new Connection(SOLANA_NETWORK);


export async function checkifinitialized(){
  const wallet = useAnchorWallet();
  const idl = require('./burndev.json');
  const preflightCommitment = "processed";
  const commitment = "confirmed"; 
  const provider = computed(
    () =>
      new anchor.AnchorProvider(connection, wallet.value!, {
        preflightCommitment,
        commitment,
      })
  );
  const mprogram = new anchor.Program(idl,provider.value)
  const MINT_SEED = "mint";

  const [Mint] = PublicKey.findProgramAddressSync(
    [Buffer.from(MINT_SEED)],
    mprogram.programId,
  );
  const tokenAccount =  anchor.utils.token.associatedAddress({
    mint: Mint,
    owner: provider.value.publicKey,
  });
  const response = await connection.getAccountInfo(tokenAccount);
  if (response == null || response.data == null){
    return false
  }
  else{
    return true
  }
}

export async function burnNFT(mint:any) {
  
console.log(mint)
  if (mint.metadata.data.collection.key === undefined && mint.metadata.data.updateauthority) {
    return new Error("NFT Not Accepted, doesn't have a collection in its metadata or UA");
  }
  
  if(mint.metadata.data.collection.verified != 1){
    return Error("NFT Not Accepted, NFT is not verified by collection")
  }
  try{
  const idl = require('./burndev.json');
  const wallet = useAnchorWallet();
  const preflightCommitment = "processed";
  const commitment = "confirmed";  

  const provider = computed(
    () =>
      new anchor.AnchorProvider(connection, wallet.value!, {
        preflightCommitment,
        commitment,
      })
  );
  const mprogram = new anchor.Program(idl,provider.value)
  const METADATA_SEED = "metadata";
  const TOKEN_METADATA_PROGRAM_ID = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s");
  const MINT_SEED = "mint";
  const mintpubkey = new PublicKey(mint.metadata.data.mint.toString());
  const [Mint] = PublicKey.findProgramAddressSync(
    [Buffer.from(MINT_SEED)],
    mprogram.programId,
  );
  const dummy = new PublicKey("1nc1nerator11111111111111111111111111111111")
  const [metadata,bumpmeta] = pda.findMetadataPda(mintpubkey)
  const tokenAccount =  anchor.utils.token.associatedAddress({
    mint: Mint,
    owner: provider.value.publicKey,
  });

  const NFTATA =  anchor.utils.token.associatedAddress({
    mint: mintpubkey,
    owner: provider.value.publicKey,
  });
  console.log("NFTATA",NFTATA.toString())
  //const collection_metadata_pda = pda.findMetadataPda(new PublicKey(mint.metadata.data.collection.key))
  const [edition_pda,edibump] = pda.findMasterEditionPda(mintpubkey)
  const [record,recbump] = pda.findTokenRecordPda(mintpubkey,NFTATA)
  const tokenAccountElmo = anchor.utils.token.associatedAddress({
    mint: Mint,
    owner: new PublicKey("eLmoWNfGJuXfKBVMRDrwQKRHJKUBCV8ty6U32baTjAv"),
  })
  let identifier
  if(mint.metadata.data.collection == undefined){
    identifier = new PublicKey(mint.metadata.data.updateauthority)
  }
  else{
    const [identifier2,idenbump] =  pda.findMetadataPda(new PublicKey(mint.metadata.data.collection.key))
    identifier = identifier2
  }
  
  console.log("mint",mintpubkey.toString())
  console.log("metadata",metadata.toString())
  console.log("record",edition_pda.toString())
  console.log("ata",NFTATA.toString())
  console.log("identifier",identifier.toString())
  console.log("record",record.toString())

  const ata = {pubkey: NFTATA, isSigner: false, isWritable: true};
  const mintpub = {pubkey: mintpubkey, isSigner: false, isWritable: true};
  const metadata2 = {pubkey:metadata, isSigner: false, isWritable: true};
  const edition =  {pubkey: edition_pda, isSigner: false, isWritable: true};
  const dummy2 = {pubkey:dummy, isSigner: false, isWritable: false};
  const record2 = {pubkey:record, isSigner: false, isWritable: true};
  const collection_metadata = {pubkey: identifier, isSigner: false, isWritable: true};
  console.log(metadata2.pubkey.toString())

  const remaining2 = [mintpub, metadata2, edition, ata, collection_metadata, dummy2, dummy2, dummy2, dummy2, record2];
  const tx = await mprogram.methods.burnprint(
    true 
  )
  const registryAccount = new PublicKey("79oVa6n7nJTSh9BVaqeUKKE2QyQvBj6hb8ZbBWX6qkPY")
  const LUT =  new PublicKey("oVoX85BXU7U3zvDe5FE1g88JH3MAuNNo5Jtd5QVzh1E")
  const sysvarInstr = new PublicKey("Sysvar1nstructions1111111111111111111111111")
  const providerpubkey = new PublicKey(provider.value.publicKey)
  const collection_metadata_tx = new PublicKey(collection_metadata.pubkey)
  const txBuildAccounts = {
    registryAccount: registryAccount ,
    tokenAccount: tokenAccount , 
    payer: providerpubkey , 
    lookupTable: LUT,
    sysvarInstructions: sysvarInstr,
    collectionMetadata: collection_metadata_tx ,  
    tokenAccountElmo: tokenAccountElmo  
  }

  const txBuild = tx.accountsPartial(txBuildAccounts);  
    

  const remaining = txBuild.remainingAccounts(
    remaining2
  )
  const txFinal = remaining.rpc({
    skipPreflight: true
  })
  console.log("Your transaction signature", txFinal);
  }catch(error){
    throw Error("Error executing burn transaction:"+ error)
  }

}
export async function initializeUser() {
  
  const idl = require('./burndev.json');
  const wallet = useAnchorWallet();
  const preflightCommitment = "processed";
  const commitment = "confirmed";  
  const provider = computed(
    () =>
      new anchor.AnchorProvider(connection, wallet.value!, {
        preflightCommitment,
        commitment,
      })
  );

  const mprogram = new anchor.Program(idl,provider.value)
  const MINT_SEED = "mint";

  const [Mint] = PublicKey.findProgramAddressSync(
    [Buffer.from(MINT_SEED)],
    mprogram.programId,
  );
    const tokenAccount =  anchor.utils.token.associatedAddress({
      mint: Mint,
      owner: provider.value.publicKey,
    });
    // Assuming necessary imports and initial setup
    const method = mprogram.methods.initializeUser();
    const accounts = method.accountsPartial({ 
      tokenAccount, 
      payer: wallet.value!.publicKey,  
    }) as any;
    try {
      const tx = await accounts.rpc();
      console.log("Transaction response:", tx);
      const result = await connection.getSignatureStatus(tx);
      if(result.value!.confirmationStatus == "confirmed"){
        return true
      }
      else{
        return false
      }
      console.log(result)
    } catch (error) {
      console.error("Error executing transaction:", error);
      
      throw Error("Error executing transaction:"+ error)
      return false

    }  
}

export async function getNftsForOwner(ownerAddress:string) {
  //const wallet = useAnchorWallet();
  const wallet = useWallet()
  const ownerPublicKey = wallet.publicKey!;
  const tokenAccounts = await connection.getParsedTokenAccountsByOwner(ownerPublicKey.value!, {
    programId: new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA')
  });
  const nftAccounts = tokenAccounts.value.filter(account => {
    const amount = account.account.data.parsed.info.tokenAmount.uiAmount;
    return amount === 1; // NFTs have an amount of 1
  });
  //console.log(nftAccounts)

  return nftAccounts;
}
interface FinalData {
  fetched?: any;
  metadata?: any;
}
export async function fetchNftMetadata(mintAddress:string) {
    try {
        const { metadata: { Metadata } } = programs;
        
        // Create PublicKey object from mint address
        const mintPublicKey = new PublicKey(mintAddress);
      
        // Derive the metadata account address
        const metadataAccount = await Metadata.getPDA(mintPublicKey);
        // Fetch the metadata

        const metadata = await Metadata.load(connection, metadataAccount);
        //console.log("metadata",metadata)
        const response = await fetch(metadata.data.data.uri);
        const data = await response.json();
        data.pubkeyAdded = mintAddress;
        const finalData: FinalData = {};
        finalData!.fetched = data;
        finalData!.metadata = metadata;

        //console.log(finalData,"finaldata")
        return finalData;

    } catch (error) {
      console.error('Error fetching metadata:', error,mintAddress);
      throw error;
    }
  }